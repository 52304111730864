document.addEventListener('DOMContentLoaded', function () {
    iniciarApp();
    cargarTraduccion();

    if (document.querySelector('.mobile-menu')) {
        eventListeners();
    }
});

function eventListeners(){
    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenu.addEventListener('click', navegacionResponsive);
}

function navegacionResponsive(){
    const navegacion = document.querySelector('.nav-principal');

    // if(navegacion.classList.contains('mostrar')){
    //     navegacion.classList.remove('mostrar');
    // } else{
    //     navegacion.classList.add('mostrar');
    // }

    navegacion.classList.toggle('mostrar')//si esta la clase mostrar la quita y si no la agrega como el ejemplo de arriba
}

function iniciarApp() {         
    crearGaleria();
}

function crearGaleria() {
    const galeria = document.querySelector('.galeria-imagenes');

    for (let i = 1; i <= 10; i++) {
        const imagen = document.createElement('picture');
        imagen.innerHTML = `<source srcset="build/img/galeria/${i}.avif" type="image/avif">
        <source srcset="build/img/galeria/${i}.webp" type="image/webp">
        <img class="imagen-item" loading="lazy" width="200" height="300" src="build/img/galeria/${i}.jpg" alt="Imagen galeria">`;

        imagen.onclick = function () {
            mostrarImagen(i);
        }

        galeria.appendChild(imagen);
    };
}

function mostrarImagen(id) {
    const imagen = document.createElement('picture');
    imagen.innerHTML = `<source srcset="build/img/galeria/${id}.avif" type="image/avif">
        <source srcset="build/img/galeria/${id}.webp" type="image/webp">
        <img class="img" loading="lazy" width="200" height="300" src="build/img/galeria/${id}.jpg" alt="Imagen galeria">`;

    //Crea el overlay con la imagen
    const overlay = document.createElement('DIV');
    overlay.appendChild(imagen);
    overlay.classList.add('overlayImagen');
    overlay.onclick = function(){
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }

    //Boton para cerrar la imagen
    const cerrarImagen = document.createElement('img');
    cerrarImagen.src = 'build/img/cerrar.svg';
    cerrarImagen.alt = 'cerrar';
    cerrarImagen.classList.add('btn-cerrar');
    cerrarImagen.onclick = function(){
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }

    overlay.appendChild(cerrarImagen);

    //lo agrega al html
    const body = document.querySelector('body');
    body.appendChild(overlay);
    body.classList.add('fijar-body');
}




// Codigo para el funcionamiento de la galeria, botones de pasar y etc

const initSlider = () => {
    const slideButtons = document.querySelectorAll(".slider-wrapper .slide-button");
    const  imageList = document.querySelector(".slider-wrapper .galeria-imagenes");
    const  sliderScrollbar = document.querySelector(".galeria .slider-scrollbar");
    const  scrollbarThumb  = sliderScrollbar.querySelector(".scrollbar-thumb");
    const maxScrollLeft = imageList.scrollWidth - imageList.clientWidth;

    //Utilizar la barra de arrastrar con el mause
    scrollbarThumb.addEventListener("mousedown", (e) => {
        const startX = e.clientX;
        const thumbPosition = scrollbarThumb.offsetLeft;


        //Actualiza la posicion de la barra al mover el mouse
        const handleMouseMove = (e) => {
            const deltaX = e.clientX - startX;
            const newThumbPosition = thumbPosition + deltaX;
            const maxThumbPosition = sliderScrollbar.getBoundingClientRect().width - scrollbarThumb.offsetWidth;

            const boundedPosition = Math.max(0, Math.min(maxThumbPosition, newThumbPosition));
            const scrollPosition = (boundedPosition / maxThumbPosition) * maxScrollLeft;

            scrollbarThumb.style.left = `${boundedPosition}px`;
            imageList.scrollLeft = scrollPosition;
        }

        // Elimina el evento de seleccionado del mouse, ya que se quedaba pegado
        const handleMouseup = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseup);
        }

        //Agrega el evento de mover la barra
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseup);
    });

    slideButtons.forEach(button => {
        button.addEventListener("click", () => {
            const direction = button.id === "prev-slide" ? -1 : 1;
            const scrollAmount = imageList.clientWidth * direction;

            imageList.scrollBy({ left: scrollAmount, behavior: "smooth"});
        });
    });

    const handleSlideButtons = () => {
        slideButtons[0].style.display= imageList.scrollLeft <= 0 ? "none" : "block";
        slideButtons[1].style.display = imageList.scrollLeft >= maxScrollLeft ? "none" : "block";
    }

    const updateScrollThumbPosition = () => {
        const scrollPosition = imageList.scrollLeft;
        const thumbPosition = (scrollPosition / maxScrollLeft) * (sliderScrollbar.clientWidth - scrollbarThumb.offsetWidth);
        scrollbarThumb.style.left = `${thumbPosition}px`;
    }

    imageList.addEventListener("scroll", () => {
        handleSlideButtons();
        updateScrollThumbPosition();
    });
}

window.addEventListener("load", initSlider);


/* codigo cambio de idioma*/

let traduccion = {};
let idiomaActual = localStorage.getItem('idioma') || "es";


//Funcion para cargar el JSON
async function cargarTraduccion(){

    try {
        const respuesta = await fetch('idioma.json');
        traduccion = await respuesta.json();
        actualizarTexos();
    } catch (error) {
        console.error('Error de carga de traduccion:' , error);
    }
}

//Funcion para cambio de idioma
function setLanguage(idioma){
    idiomaActual = idioma;
    actualizarTexos();
}


//funcion para actualizar los textos en el html
function actualizarTexos(){
    document.querySelectorAll('[data-translate-key]').forEach(element => {
        const key = element.getAttribute('data-translate-key');
        element.textContent = traduccion[idiomaActual][key];
    });
}